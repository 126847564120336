import API from '../../utils/api';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SigninContext from '../../contexts/SigninContext';
import {
    Button,
    Card,
    DropdownSelect,
    FormField,
    Heading,
    IconReload,
    InlineFeedback,
    Input,
    Label,
    LoadingButton,
    Radio,
} from '@zillow/constellation';
import { API_ADMIN_UPDATE_AGENT_REQUEST_STATUS } from '../../constants/ApiConstants';

const StyledForm = styled.form`
    margin-top: 40px;
`;

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const StyleCard = styled(Card)`
    padding: 2px;
    margin-bottom: 30px;
    margin-top: 30px;
    border-bottom: 3px solid #c3cddb;
    box-shadow: none;
    padding-left: 50px;
    min-height: 430px;
`;

const StyledFormField = styled(FormField)`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 30px;
`;

const StyledLabel = styled(Label)`
    margin-right: 1rem;
    width: 180px;
`;

const StyledRadioLabel = styled(Label)`
    margin-right: 1rem;
    margin-left: 1rem;
    width: 30px;
`;

const StyledInput = styled(Input)`
    width: 50%;
    background-color: none;
`;

const StyledDropdownSelect = styled(DropdownSelect)`
    width: 50%;
`;
const StyledButton = styled(Button)`
    margin-bottom: 15px;
    margin-left: 160px;
`;

const StyledLoadingButtonDiv = styled.span`
    div[class^='StyledLoadingMask'] {
        margin-top: 10px;
        margin-left: 70px;
        margin-bottom: 10px;
    }
    button {
        padding: 9px 20px;
    }
`;

const StyledInlineFeedbackDiv = styled.div`
    text-align: left;
    background-color: #fff9ad;
    margin-left: 80px;
    width: 400px;
`;

const statusOptions = [
    { value: 'finished', label: 'FINISHED' },
    { value: 'jiraTicketCreated', label: 'JIRA_TICKET_CREATED' },
    { value: 'pendingTransfer', label: 'PENDING_TRANSFER' },
    { value: 'readyToExport', label: 'READY_TO_EXPORT' },
    { value: 'requestPending', label: 'REQUEST_PENDING' },
    { value: 'retry', label: 'RETRY' },
    { value: 'requestSent', label: 'REQUEST_SENT' },
    { value: 'responseError', label: 'RESPONSE_ERROR' },
    { value: 'responseSuccess', label: 'RESPONSE_SUCCESS' },
    { value: 'responseSuccessNoop', label: 'RESPONSE_SUCCESS_NOOP' },
];

const AgentRequestStatusUpdate = () => {
    const {
        state: { csrfToken },
    } = useContext(SigninContext);
    const [isMultipleAgent, setIsMultipleAgent] = useState(true);
    const [userRequestId, setUserRequestId] = useState('');
    const [agentId, setAgentId] = useState('-1');
    const [currentRequestStatus, setCurrentRequestStatus] = useState(null);
    const [newRequestStatus, setNewRequestStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [filteredStatusOptions, setFilteredStatusOptions] = useState(statusOptions);

    useEffect(() => {
        if (currentRequestStatus) {
            setFilteredStatusOptions(
                statusOptions.filter(option => option.label !== currentRequestStatus)
            );
        } else {
            setFilteredStatusOptions(statusOptions);
        }
    }, [currentRequestStatus]);

    const getErrorMessage = errorMessageText => {
        return (
            <StyledInlineFeedbackDiv>
                <InlineFeedback appearance="error">{errorMessageText}</InlineFeedback>
            </StyledInlineFeedbackDiv>
        );
    };

    const getInfoMessage = infoMessageText => {
        return (
            <StyledInlineFeedbackDiv>
                <InlineFeedback appearance="info">{infoMessageText}</InlineFeedback>
            </StyledInlineFeedbackDiv>
        );
    };

    const handleStatusChange = (selectedOption, setStatusFunction) => {
        setStatusFunction(selectedOption);
    };

    const submitAgentRequestStatusUpdate = e => {
        e.preventDefault();
        setErrorMessage('');
        setInfoMessage('');

        if (!userRequestId || !agentId || !currentRequestStatus || !newRequestStatus) {
            setErrorMessage(getErrorMessage('All fields are required!'));
            return;
        }
        setIsLoading(true);

        API({
            url: API_ADMIN_UPDATE_AGENT_REQUEST_STATUS(
                userRequestId,
                agentId,
                currentRequestStatus,
                newRequestStatus
            ),
            headers: {
                'X-Csrf-Token': csrfToken,
            },
            method: 'POST',
        })
            .then(res => {
                if (res.data.length > 0) {
                    setInfoMessage(getInfoMessage('Successfully updated the agent request status'));
                    setIsLoading(false);
                } else {
                    setInfoMessage(getErrorMessage('Please verify the given values'));
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(getErrorMessage('Something went wrong. Please try again later..!'));
                setIsLoading(false);
            });
    };

    return (
        <div>
            <StyledHeading level="4"> Agent Request Status Update</StyledHeading>
            <StyleCard>
                <StyledForm onSubmit={submitAgentRequestStatusUpdate}>
                    <StyledFormField marginBottom="sm">
                        <StyledLabel htmlFor="userRequestId">User Request Id</StyledLabel>
                        <StyledInput
                            name="userRequestId"
                            id="userRequestId"
                            onChange={e => setUserRequestId(e.target.value)}
                        />
                    </StyledFormField>
                    <StyledFormField marginBottom="sm">
                        <StyledLabel htmlFor="multipleAgents">Multiple Agents?</StyledLabel>
                        <StyledRadioLabel htmlFor="yes">Yes</StyledRadioLabel>
                        <Radio
                            value="yes"
                            name="multipleAgents"
                            checked={isMultipleAgent}
                            onChange={() => {
                                setIsMultipleAgent(true);
                                setAgentId('-1');
                            }}
                        />
                        <StyledRadioLabel htmlFor="no">No</StyledRadioLabel>
                        <Radio
                            value="no"
                            name="multipleAgents"
                            checked={!isMultipleAgent}
                            onChange={() => setIsMultipleAgent(false)}
                        />
                    </StyledFormField>
                    {!isMultipleAgent && (
                        <StyledFormField>
                            <StyledLabel htmlFor="agentId">Agent Id</StyledLabel>
                            <StyledInput
                                name="agentId"
                                id="agentId"
                                onChange={e => setAgentId(e.target.value)}
                            />
                        </StyledFormField>
                    )}
                    <StyledFormField marginBottom="sm">
                        <StyledLabel htmlFor="currentRequestStatus">
                            Current Request Status
                        </StyledLabel>
                        <StyledDropdownSelect
                            placeholder="Select Current Request Status"
                            options={statusOptions}
                            onChange={selectedOption =>
                                handleStatusChange(selectedOption, setCurrentRequestStatus)
                            }
                            showLabelForValue
                        />
                    </StyledFormField>
                    <StyledFormField marginBottom="sm">
                        <StyledLabel htmlFor="newRequestStatus">New Request Status</StyledLabel>
                        <StyledDropdownSelect
                            placeholder="Select New Request Status"
                            options={filteredStatusOptions}
                            onChange={selectedOption =>
                                handleStatusChange(selectedOption, setNewRequestStatus)
                            }
                            showLabelForValue
                        />
                    </StyledFormField>
                    {isLoading ? (
                        <StyledLoadingButtonDiv>
                            <LoadingButton size="lg" buttonType="primary" loading>
                                <IconReload size="xs" /> Processing
                            </LoadingButton>
                        </StyledLoadingButtonDiv>
                    ) : (
                        <StyledButton buttonType="primary" type="submit" marginTop="sm">
                            <IconReload size="xs" /> Process
                        </StyledButton>
                    )}
                    {errorMessage} {infoMessage}
                </StyledForm>
            </StyleCard>
        </div>
    );
};

export default AgentRequestStatusUpdate;
